// extracted by mini-css-extract-plugin
export const logo = "partners-create-account-module--logo--2z57F";
export const pageWrapper = "partners-create-account-module--pageWrapper--33yXd";
export const createAccountForm = "partners-create-account-module--create-account-form--1Kv5x";
export const formGroup = "partners-create-account-module--form-group--3YvAG";
export const header = "partners-create-account-module--header--2n5AH";
export const banner = "partners-create-account-module--banner--2Dqgk";
export const validation = "partners-create-account-module--validation--1O9aH";
export const checkbox = "partners-create-account-module--checkbox--2JDy2";
export const buttonGroup = "partners-create-account-module--button-group--19yIG";
export const backButton = "partners-create-account-module--back-button--1uJ9S";