import React from 'react';
import { useFormik } from 'formik';
import { Button, Input, Checkbox, Form } from 'semantic-ui-react';
import { connect, useDispatch } from 'react-redux';
import Actions from 'src/state/root-actions';

import Layout from 'src/components/layout/Layout';
import { BackToPill, Banner, Icon } from 'src/components/shared';

import Selectors from 'src/state/root-selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import * as Styles from 'src/styles/partners-create-account.module.scss';
import Colors from 'src/utils/colors';

import { navigate } from 'gatsby-link';
import { linkToExternal } from 'src/utils/url-utils';
import { ZELDA_HOME_LINK } from 'src/constants/url-constants';
interface SuccessProps {
    isEligible: boolean;
    memberName: Record<string, string>;
    email: string;
}

const connector = connect((state: IState) => ({
    isEligible: Selectors.subscriptions?.partnerActivation?.activation(state)?.data?.is_eligible,
    memberName: Selectors.subscriptions?.partnerActivation?.activation(state)?.data?.provider_data,
    email: Selectors.account.email(state),
}));

const Success = ({ isEligible, memberName, email }: SuccessProps) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const dispatch = useDispatch();

    const toggleShowPassword = () => setShowPassword((showPasswordState) => !showPasswordState);

    const formik = useFormik({
        initialValues: {
            password: '',
            email: email,
            lastName: memberName?.last_name,
            firstName: memberName?.first_name,
            optIn: true,
        },
        onSubmit: (values) => {
            dispatch(
                Actions.auth.signup.trigger({
                    ...values,
                    onFailure: (err) => console.log('oops', err),
                    onSuccess: (data) => {
                        console.log('account created', data);
                        dispatch(Actions.account.getAllAttrs.trigger());
                        dispatch(Actions.account.getTokenAttributes.trigger());
                        linkToExternal(ZELDA_HOME_LINK);
                    },
                })
            );
        },
    });

    const pageContext = {
        theme: {
            layout: 'none',
        },
    };

    if (!isEligible) {
        navigate(-1);
        return null;
    }

    const formComplete =
        formik.values.lastName && formik.values.firstName && formik.values.password;

    const fieldValidations = [
        {
            message: 'must be at least 8 characters',
            validation: formik.values.password.length >= 8,
        },
        {
            message: 'contain at least 1 uppercase letter',
            validation: formik.values.password.match(/[A-Z]/g),
        },
        {
            message: 'contain at least 1 number',
            validation: formik.values.password.match(/\d/g),
        },
    ];

    return (
        <Layout pageContext={pageContext}>
            <div className={Styles.pageWrapper}>
                <div className={Styles.header}>
                    <Icon name="pv-logo-name" size={38} className={Styles.logo} />

                    <Banner withIcon type="SUCCESS" className={Styles.banner}>
                        You’ve activated your free subscription. Create your account below to get
                        started.
                    </Banner>
                </div>
                <div className={Styles.createAccountForm}>
                    <Form>
                        <Form.Field className={Styles.formGroup}>
                            <Form.Input
                                fluid
                                type="text"
                                id="firstName"
                                name="firstName"
                                placeholder="first name"
                                onChange={formik.handleChange}
                                value={formik.values.firstName}
                                label={{
                                    htmlFor: 'firstName',
                                    children: 'first name',
                                }}
                            />
                        </Form.Field>

                        <Form.Field className={Styles.formGroup}>
                            <Form.Input
                                fluid
                                type="text"
                                id="lastName"
                                name="lastName"
                                placeholder="last name"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                label={{
                                    htmlFor: 'lastName',
                                    children: 'last name',
                                }}
                            />
                        </Form.Field>

                        <Form.Field className={Styles.formGroup}>
                            <Form.Input
                                fluid
                                id="password"
                                name="password"
                                placeholder="create password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                type={showPassword ? 'text' : 'password'}
                                icon={
                                    <Icon
                                        size={24}
                                        name={`pv-eyeball${showPassword ? '-off' : ''}`}
                                        onClick={toggleShowPassword}
                                    />
                                }
                                label={{
                                    htmlFor: 'password',
                                    children: 'create password',
                                }}
                            />
                            <div>
                                {fieldValidations.map((fv, i) => {
                                    return (
                                        <div key={`validation-${i}`} className={Styles.validation}>
                                            <Icon
                                                name={
                                                    !!fv.validation
                                                        ? 'pv-checkmark-circle'
                                                        : 'pv-info'
                                                }
                                                style={{
                                                    color: !!fv.validation
                                                        ? Colors.sea
                                                        : Colors.lightBlack,
                                                }}
                                                size={16}
                                            />
                                            <p className="p3">{fv.message}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </Form.Field>

                        <label className={Styles.checkbox}>
                            <Checkbox
                                name="optIn"
                                checked={formik.values.optIn}
                                label="Keep me up to date with news and exclusive offers."
                                onChange={() => formik.setFieldValue('optIn', !formik.values.optIn)}
                            />
                        </label>

                        <div className={Styles.buttonGroup}>
                            <Button
                                fluid
                                primary
                                size="big"
                                type="submit"
                                onClick={formik.handleSubmit}
                                disabled={
                                    !formComplete || !fieldValidations.every((fv) => fv.validation)
                                }
                            >
                                Create Account
                            </Button>
                            <BackToPill to={-1} className={Styles.backButton} label="go back" />
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};

export default connector(Success);
